.signup-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  .card-container {
    width: 35%;
    padding: 20px;
    // box-sizing: border-box;
    h2 {
      display: flex;
      justify-content: center;
      font-weight: 500;
      font-size: 28px;
      margin-top: 0px;
      justify-content: left;
    }
    p {
      font-size: 18px;
      margin-right: 10px;
      margin: 0px;
      font-weight: 400;
    }
  }
  .ant-card {
    margin: 4.5rem 0;
    margin-right: 3rem;
  }

  .ant-form-item-label {
    font-weight: 500;
  }

  .form-container {
    // height: 550px;
    display: flex;
    flex-direction: column;
    width: 40%;
    padding: 2rem;
    border-radius: 8px;
    background-color: #fff;
    border: rgb(175, 171, 161) 1px solid;
    // border: rgb(175 171 161 / 27%) 1px solid;
    --tw-bg-opacity: 1;

    h2 {
      margin: 0px;
      text-align: center;
      padding-bottom: 1rem;
      display: flex;
      justify-content: left;
      font-weight: 500;
    }
    .title {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
      font-weight: 500;
    }
  }
}
.login-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;

  .login-box {
    display: flex;
    flex-direction: column;
    width: 445px;
    padding: 1rem 2rem;
    border-radius: 8px;
    background-color: #fff;
    // border: rgb(175 171 161 / 27%) 1px solid;
    border: rgb(175, 171, 161) 1px solid;

    --tw-bg-opacity: 1;
    h2 {
      text-align: center;
      padding-bottom: 10px;
      display: flex;
      justify-content: center;
      font-weight: 500;
    }

    .login-form-button {
      text-align: center;
      width: 100%;
      margin-top: 1rem;
      a {
        font-size: 16px;
      }
    }
  }
}
