.onboarding-container {
  // margin: 0 auto;
  // margin-top: 2rem;
  // width: 90%;

  .card-header-h3 {
    justify-content: "center";
    font-size: "20px";
    font-weight: "600";
    color: "#000";
  }
  .checkbox-container {
    .ant-checkbox-checked .ant-checkbox-inner {
      background: #52c41a;
      border-color: #52c41a;
    }
  }

  .card-border-config {
    .ant-card-body {
      // background: #1b2d8d;
      border-radius: 8px 8px 0 0;
    }
  }
  .card-border-module {
    .ant-card-body {
      // background: #1b2d8d;
      border-radius: 8px 8px;
    }
  }
  .card-container {
    .ant-card {
      margin: 0px !important;
    }

    .ant-progress-line {
      margin: 0 !important;
    }
    .ant-card-actions > li {
      margin: 0 !important;
    }
    .ant-card-actions {
      // background: #1b2d8d;
      border-top: none;
      margin: 0px !important;
    }
    .ant-progress {
      border-radius: 0px !important;
    }
    .ant-progress-bg {
      background: #52c41a;
    }
  }
}
