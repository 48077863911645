@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Inter&display=swap");

html {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  text-size-adjust: 100%;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  // overflow: visible;
  overflow: auto;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inter", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.search-label {
  margin-bottom: 0.25rem;
  font-size: 14px;
  font-weight: 500;
  color: #1f1f20;
}
.app-loading {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 1rem;
    align-items: center;

    .logo-container {
      color: #fff;
      font-size: 24px;
      margin: 0;

      span {
        margin-right: 1rem;
      }
    }
    .userName-container {
      font-weight: 500;
      color: #fff;
    }

    .notification-icon-container {
      color: #fff;

      .anticon-bell {
        color: #fff;
        font-size: 22px;
      }
    }
  }

  .ant-layout {
    background: none;
  }
  .ant-card-bordered {
    box-shadow: 0 10px 20px #00000008, 0 2px 2px #00000005;
    border-radius: 10px;
  }

  // .left-menu-container {
  //   padding: 1rem 0;
  //   height: calc(100vh - 64px);
  //   background-color: #fbfbfb;
  //   .left-menu {
  //     background: none;
  //     border: none;
  //     .ant-menu-sub {
  //       background: none;
  //       margin-bottom: 2rem;

  //       // .ant-menu-item {
  //       //   margin-right: 0;
  //       //   width: 100%;
  //       //   border-radius: 0;
  //       // }
  //     }
  //   }
  // }
  .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background-color: #fbfbfb;
  }
  .sidebar__container {
    padding: 1rem 0;
    background-color: #fbfbfb;
    display: flex;
    height: calc(100vh - 64px);
    flex-direction: column;
    justify-content: space-between;
  }

  .sidebar__container__menu {
    box-sizing: border-box;
    overflow-y: auto;
  }

  .with-header {
    height: calc(100vh - 64px);
  }
  .without-header {
    height: 100vh;
  }

  .page-container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 100px);
    padding: 1rem 2.5rem;
    overflow: auto;
    background: #fff;

    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 1.26667;
      letter-spacing: -0.00833em;
      margin: 0 0 2rem;
    }
    .sub-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 1.26667;
      letter-spacing: -0.00833em;
      margin: 2rem 0rem 1rem 0;
    }
    .body-text {
      font-size: 13px;
      font-family: "Inter", serif;
      margin: 0;
    }
    .para-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 1.26667;
      letter-spacing: -0.00833em;
      margin: 2rem 0rem 1rem 0;
    }
    .ant-select,
    .ant-input {
      width: 100%;
    }
    .ant-modal-content {
      width: 100%;
      height: 573px;
    }
    .tab-container {
      margin-top: 1rem;
    }
    .ant-badge.ant-badge-status {
      .ant-badge-status-dot {
        width: 10px !important;
        height: 10px !important;
      }
    }

    .video-container {
      .ant-modal {
        .ant-modal-content {
          background-color: #000 !important;
        }
        .ant-modal-header {
          background: #000 !important;
          .ant-modal-title {
            color: #fff !important;
          }
        }
      }
    }
    .search-box-container {
      // .ant-col{
      //     padding: 0 16px;
      // }
      margin-bottom: 2rem;
      gap: 2rem;
    }

    .search-component {
      width: 21rem;
    }

    .chart-first-row {
      margin-bottom: 2rem;
    }

    .chart-container {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02),
        0 2px 4px 0 rgba(0, 0, 0, 0.02);
      border: 1px solid #f4f4f4;

      padding: 1rem;
    }
  }
}

.chat-widget-container {
  width: 400px; /* Adjust width as needed */
  max-height: 400px; /* Adjust height as needed */
  display: flex;
  flex-direction: column;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column-reverse; /* Display messages in reverse order */
}

.message {
  display: flex;
  justify-content: flex-end; /* User messages on the right */
  margin-bottom: 10px;
}

.message.bot {
  justify-content: flex-start; /* Bot messages on the left */
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.chat-icon {
  position: fixed;
  bottom: 20px;
  right: 10px;
  font-size: 32px;
  color: #2995f3;
}

.chat-input {
  display: flex;
  justify-content: space-between;

  button {
    margin-left: 2rem;
  }
}

.toolbar {
  border-radius: 4px;

  .tool-1 {
    margin-right: -18px;
  }

  .para {
    font-size: 10px;
    font-family: "Inter", serif;
    margin: 0;
  }

  .num {
    font-size: larger;
  }

  .dotted {
    text-align: end;
    margin-bottom: 0;
    margin-right: 1rem;
    margin-top: 0;
  }

  .leg1 {
    font-size: 10px;
    font-family: "Inter", serif;
    // margin-top: -8px;
    margin: 0;
  }

  .tool-2 {
    margin-right: -28px;
  }

  .leg2 {
    font-size: 10px;
    font-family: "Inter", serif;
    margin-top: 35px;
    margin-right: 14px;
  }
}

.graph-dropdown {
  margin-top: 1rem;
}

*:focus {
  outline: none;
}

.wallet-search {
  border-color: #0000;
  border-style: solid;
  border-width: 1px;

  input {
    border: none;
  }

  &:focus-within {
    border-bottom-color: rgb(64, 150, 255);
  }
}
.ant-list-item-meta-description {
  color: #000 !important;
}
.ant-input-group-addon {
  display: none !important;
}
.createWindow {
  margin-bottom: 1rem;
  margin-top: 2rem;
  .ant-collapse-header-text {
    color: blue;
    font-weight: 600;
  }
}

/* Custom styling for the DataGrid */
/* Custom styling to mimic Ant Design table appearance */
.ant-style-data-grid {
  border: none !important; /* Match Ant Design border color */
  border-radius: 2px !important; /* Match Ant Design border-radius */
  overflow: hidden !important; /* Hide overflow for cleaner look */
}

.ant-style-data-grid .MuiDataGrid-colCell {
  background-color: #fafafa !important; /* Match Ant Design header background color */
  color: #333 !important; /* Match Ant Design header text color */
  border-bottom: 1px solid #e8e8e8 !important; /* Match Ant Design border color */
}

.ant-style-data-grid .MuiDataGrid-oddRows {
  background-color: #ffffff !important; /* Match Ant Design odd row background color */
}

.ant-style-data-grid .Mui-selected {
  background-color: #e6f7ff !important; /* Match Ant Design selected row background color */
}

.ant-style-data-grid .MuiDataGrid-row:hover {
  background-color: #f5f5f5 !important; /* Match Ant Design row hover background color */
}

/* Optional: Style the odd/even rows */
.custom-data-grid .MuiDataGrid-oddRows {
  background-color: #f9f9f9 !important;
}

/* Optional: Style the selected rows */
.custom-data-grid .Mui-selected {
  background-color: #cfd8dc !important;
}

/* Optional: Style the hover effect */
.custom-data-grid .MuiDataGrid-row:hover {
  background-color: #e3f2fd !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}
.MuiDataGrid-cellContent {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}
.MuiDataGrid-columnHeadersInner {
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.2s ease;
}
.MuiSvgIcon-root {
  height: 16px !important;
  width: 16px !important;
}
.green-switch {
  background-color: #52c41a !important;
  border-color: #52c41a !important;
}
.authModal {
  .ant-modal-content {
    .ant-select,
    .ant-input {
      width: 100%;
    }
  }
}
.MuiDataGrid-main {
  min-height: 400px;
}
.tenant-config-tab {
  .ant-tabs-tab {
    font-weight: 500 !important;
  }
}

.react-joyride__tooltip {
  // background-color: rgb(250, 219, 255) !important;
  color: #000 !important; /* Change text color if needed */
}
// .__floater__arrow {
//   svg {
//     polygon {
//       fill: rgb(250, 219, 255) !important;
//     }
//   }
// }
.__floater__body {
  div {
    text-align: left;
  }
  h4 {
    color: rgb(22, 119, 255);
    font-size: 20px;
  }
}

.endpoint-collapse-container {
  .ant-collapse-header {
    font-weight: 700;
  }
}
.ant-select-dropdown .ant-select-item-option-content {
  flex: auto !important;
  overflow: hidden !important;
  white-space: normal !important;
  text-overflow: ellipsis !important;
}
